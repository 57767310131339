import { Container, Row, Col } from 'reactstrap';
import Header from '../components/Header';

const BetaPage = () => {
    return (
        <Container fluid>
            <Row className='header-row bg-dark mb-5 border-bottom border-secondary'>
                <Header />
            </Row>
            <Row className='text-center'>
                <h2>Beta Log</h2>
                <small>
                        Last updated 16 Jan 2024
                        <br />
                        Beta v0.1.1
                </small>
                <Col className='mt-5' md='6'>
                    <h5 className='fw-bold'>Change Log</h5>
                    <p className='mb-1'>16 Jan 2024 (v0.1.1):</p>
                    <ul className='list-group list-group-flush'>
                        <li className='list-group-item rounded-0 w-50 mx-auto'>
                            Updated dark mode stylings.
                        </li>
                        <li className='list-group-item rounded-0 w-50 mx-auto'>
                            Adjusted header and dark/light mode toggling.
                        </li>
                        <li className='list-group-item rounded-0 w-50 mx-auto'>
                            Resolved issue with site header not rendering on mobile.
                        </li>
                        <li className='list-group-item rounded-0 w-50 mx-auto'>
                            Resolved issue with glossary pagination not rendering on mobile.
                        </li>
                        <li className='list-group-item rounded-0 w-50 mx-auto'>
                            Improved stories for tablet devices.
                        </li>
                        <li className='list-group-item rounded-0 w-50 mx-auto'>
                            Resolved issue with mobile devices not redirecting to https.
                        </li>
                    </ul>
                </Col>
                <Col className='mt-5' md='6'>
                    <h5 className='fw-bold'>Planned Changes</h5>
                    <p className='mb-1'>30 Jan 2024:</p>
                    <ul className='list-group list-group-flush'>
                        <li className='list-group-item rounded-0 w-50 mx-auto'>
                            Improve accessibility.
                        </li>
                        <li className='list-group-item rounded-0 w-50 mx-auto'>
                            Identify and address issues with saved logins.
                        </li>
                        <li className='list-group-item rounded-0 w-50 mx-auto'>
                            Fixed bug causing header not to render on tablet devices.
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
};

export default BetaPage;