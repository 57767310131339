export const MEGADIVERSE_COUNTRIES = [
    'United States',
    'Mexico',
    'Colombia',
    'Ecuador',
    'Peru',
    'Venezuela',
    'Brazil',
    'Democratic Republic of the Congo',
    'South Africa',
    'Madagascar',
    'India',
    'Malaysia',
    'Indonesia',
    'Philippines',
    'Papua New Guinea',
    'China',
    'Australia'
];