export const ENDANGERED_SPECIES = [
    {
      name: 'Antarctica',
      mammals: 2,
      birds: 5,
      reptiles: 0,
      amphibians: 0,
      fish: 1,
      molluscs: 0,
      otherInvertebrates: 0,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 8
    },
    {
      name: 'Bouvet Island',
      mammals: 1,
      birds: 1,
      reptiles: 0,
      amphibians: 0,
      fish: 0,
      molluscs: 0,
      otherInvertebrates: 0,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 2
    },
    {
      name: 'French Southern and Antarctic Lands',
      mammals: 3,
      birds: 14,
      reptiles: 7,
      amphibians: 0,
      fish: 10,
      molluscs: 0,
      otherInvertebrates: 0,
      plants: 2,
      fungi: 0,
      chromists: 0,
      total: 36
    },
    {
      name: 'Heard Island and McDonald Islands',
      mammals: 1,
      birds: 10,
      reptiles: 0,
      amphibians: 0,
      fish: 2,
      molluscs: 0,
      otherInvertebrates: 0,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 13
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      mammals: 3,
      birds: 6,
      reptiles: 0,
      amphibians: 0,
      fish: 0,
      molluscs: 0,
      otherInvertebrates: 0,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 9
    },
    {
      name: 'Anguilla',
      mammals: 1,
      birds: 0,
      reptiles: 8,
      amphibians: 0,
      fish: 48,
      molluscs: 0,
      otherInvertebrates: 25,
      plants: 3,
      fungi: 0,
      chromists: 0,
      total: 85
    },
    {
      name: 'Antigua and Barbuda',
      mammals: 2,
      birds: 3,
      reptiles: 8,
      amphibians: 0,
      fish: 48,
      molluscs: 0,
      otherInvertebrates: 25,
      plants: 2,
      fungi: 0,
      chromists: 0,
      total: 88
    },
    {
      name: 'Aruba',
      mammals: 2,
      birds: 1,
      reptiles: 2,
      amphibians: 0,
      fish: 50,
      molluscs: 1,
      otherInvertebrates: 25,
      plants: 5,
      fungi: 0,
      chromists: 0,
      total: 86
    },
    {
      name: 'Bahamas',
      mammals: 5,
      birds: 9,
      reptiles: 13,
      amphibians: 0,
      fish: 60,
      molluscs: 1,
      otherInvertebrates: 27,
      plants: 28,
      fungi: 0,
      chromists: 0,
      total: 143
    },
    {
      name: 'Barbados',
      mammals: 3,
      birds: 3,
      reptiles: 7,
      amphibians: 0,
      fish: 47,
      molluscs: 0,
      otherInvertebrates: 26,
      plants: 4,
      fungi: 0,
      chromists: 0,
      total: 90
    },
    {
      name: 'Bermuda',
      mammals: 4,
      birds: 3,
      reptiles: 4,
      amphibians: 0,
      fish: 35,
      molluscs: 2,
      otherInvertebrates: 35,
      plants: 8,
      fungi: 0,
      chromists: 0,
      total: 91
    },
    {
      name: 'Bonaire, Sint Eustatius and Saba',
      mammals: 3,
      birds: 1,
      reptiles: 7,
      amphibians: 0,
      fish: 51,
      molluscs: 0,
      otherInvertebrates: 27,
      plants: 6,
      fungi: 0,
      chromists: 0,
      total: 95
    },
    {
      name: 'Cayman Islands',
      mammals: 1,
      birds: 1,
      reptiles: 11,
      amphibians: 0,
      fish: 43,
      molluscs: 2,
      otherInvertebrates: 28,
      plants: 23,
      fungi: 0,
      chromists: 0,
      total: 109
    },
    {
      name: 'Cuba',
      mammals: 10,
      birds: 17,
      reptiles: 44,
      amphibians: 49,
      fish: 66,
      molluscs: 0,
      otherInvertebrates: 38,
      plants: 196,
      fungi: 0,
      chromists: 0,
      total: 420
    },
    {
      name: 'Curaçao',
      mammals: 3,
      birds: 1,
      reptiles: 5,
      amphibians: 0,
      fish: 46,
      molluscs: 0,
      otherInvertebrates: 26,
      plants: 4,
      fungi: 0,
      chromists: 0,
      total: 85
    },
    {
      name: 'Dominica',
      mammals: 3,
      birds: 6,
      reptiles: 3,
      amphibians: 2,
      fish: 49,
      molluscs: 0,
      otherInvertebrates: 25,
      plants: 20,
      fungi: 0,
      chromists: 0,
      total: 108
    },
    {
      name: 'Dominican Republic',
      mammals: 6,
      birds: 17,
      reptiles: 59,
      amphibians: 35,
      fish: 47,
      molluscs: 0,
      otherInvertebrates: 36,
      plants: 104,
      fungi: 0,
      chromists: 0,
      total: 304
    },
    {
      name: 'Grenada',
      mammals: 3,
      birds: 2,
      reptiles: 7,
      amphibians: 1,
      fish: 48,
      molluscs: 0,
      otherInvertebrates: 26,
      plants: 7,
      fungi: 0,
      chromists: 0,
      total: 94
    },
    {
      name: 'Guadeloupe',
      mammals: 4,
      birds: 4,
      reptiles: 14,
      amphibians: 3,
      fish: 47,
      molluscs: 1,
      otherInvertebrates: 29,
      plants: 19,
      fungi: 0,
      chromists: 0,
      total: 121
    },
    {
      name: 'Haiti',
      mammals: 3,
      birds: 17,
      reptiles: 66,
      amphibians: 45,
      fish: 61,
      molluscs: 0,
      otherInvertebrates: 30,
      plants: 228,
      fungi: 0,
      chromists: 0,
      total: 450
    },
    {
      name: 'Jamaica',
      mammals: 7,
      birds: 10,
      reptiles: 29,
      amphibians: 20,
      fish: 48,
      molluscs: 0,
      otherInvertebrates: 30,
      plants: 215,
      fungi: 0,
      chromists: 0,
      total: 359
    },
    {
      name: 'Martinique',
      mammals: 1,
      birds: 5,
      reptiles: 9,
      amphibians: 2,
      fish: 45,
      molluscs: 2,
      otherInvertebrates: 23,
      plants: 19,
      fungi: 1,
      chromists: 0,
      total: 107
    },
    {
      name: 'Montserrat',
      mammals: 2,
      birds: 3,
      reptiles: 4,
      amphibians: 1,
      fish: 45,
      molluscs: 0,
      otherInvertebrates: 25,
      plants: 8,
      fungi: 0,
      chromists: 0,
      total: 88
    },
    {
      name: 'Puerto Rico',
      mammals: 2,
      birds: 13,
      reptiles: 16,
      amphibians: 14,
      fish: 49,
      molluscs: 0,
      otherInvertebrates: 25,
      plants: 77,
      fungi: 1,
      chromists: 0,
      total: 197
    },
    {
      name: 'Saint Barthélemy',
      mammals: 1,
      birds: 0,
      reptiles: 4,
      amphibians: 0,
      fish: 36,
      molluscs: 0,
      otherInvertebrates: 25,
      plants: 2,
      fungi: 0,
      chromists: 0,
      total: 68
    },
    {
      name: 'Saint Kitts and Nevis',
      mammals: 2,
      birds: 3,
      reptiles: 6,
      amphibians: 1,
      fish: 46,
      molluscs: 0,
      otherInvertebrates: 25,
      plants: 5,
      fungi: 0,
      chromists: 0,
      total: 88
    },
    {
      name: 'Saint Lucia',
      mammals: 2,
      birds: 7,
      reptiles: 9,
      amphibians: 0,
      fish: 46,
      molluscs: 0,
      otherInvertebrates: 25,
      plants: 16,
      fungi: 0,
      chromists: 0,
      total: 105
    },
    {
      name: 'Sint Maarten',
      mammals: 2,
      birds: 1,
      reptiles: 7,
      amphibians: 0,
      fish: 44,
      molluscs: 0,
      otherInvertebrates: 25,
      plants: 2,
      fungi: 0,
      chromists: 0,
      total: 81
    },
    {
      name: 'Saint Vincent and the Grenadines',
      mammals: 2,
      birds: 4,
      reptiles: 8,
      amphibians: 1,
      fish: 47,
      molluscs: 0,
      otherInvertebrates: 25,
      plants: 8,
      fungi: 0,
      chromists: 0,
      total: 95
    },
    {
      name: 'Sint Maarten',
      mammals: 2,
      birds: 1,
      reptiles: 7,
      amphibians: 0,
      fish: 44,
      molluscs: 0,
      otherInvertebrates: 25,
      plants: 1,
      fungi: 0,
      chromists: 0,
      total: 80
    },
    {
      name: 'Trinidad and Tobago',
      mammals: 3,
      birds: 5,
      reptiles: 7,
      amphibians: 4,
      fish: 61,
      molluscs: 0,
      otherInvertebrates: 26,
      plants: 58,
      fungi: 0,
      chromists: 0,
      total: 164
    },
    {
      name: 'Turks and Caicos Islands',
      mammals: 2,
      birds: 3,
      reptiles: 8,
      amphibians: 0,
      fish: 45,
      molluscs: 0,
      otherInvertebrates: 25,
      plants: 10,
      fungi: 0,
      chromists: 0,
      total: 93
    },
    {
      name: 'Virgin Islands',
      mammals: 1,
      birds: 2,
      reptiles: 15,
      amphibians: 2,
      fish: 47,
      molluscs: 0,
      otherInvertebrates: 25,
      plants: 25,
      fungi: 0,
      chromists: 0,
      total: 117
    },
    {
      name: 'Virgin Islands',
      mammals: 1,
      birds: 3,
      reptiles: 14,
      amphibians: 3,
      fish: 47,
      molluscs: 0,
      otherInvertebrates: 23,
      plants: 22,
      fungi: 0,
      chromists: 0,
      total: 113
    },
    {
      name: 'China',
      mammals: 78,
      birds: 94,
      reptiles: 55,
      amphibians: 148,
      fish: 208,
      molluscs: 15,
      otherInvertebrates: 93,
      plants: 720,
      fungi: 8,
      chromists: 0,
      total: 1419
    },
    {
      name: 'Hong Kong',
      mammals: 6,
      birds: 21,
      reptiles: 7,
      amphibians: 6,
      fish: 33,
      molluscs: 1,
      otherInvertebrates: 10,
      plants: 16,
      fungi: 0,
      chromists: 0,
      total: 100
    },
    {
      name: 'Japan',
      mammals: 29,
      birds: 50,
      reptiles: 26,
      amphibians: 45,
      fish: 166,
      molluscs: 61,
      otherInvertebrates: 145,
      plants: 75,
      fungi: 15,
      chromists: 0,
      total: 612
    },
    {
      name: 'North Korea',
      mammals: 10,
      birds: 30,
      reptiles: 2,
      amphibians: 1,
      fish: 51,
      molluscs: 0,
      otherInvertebrates: 4,
      plants: 20,
      fungi: 2,
      chromists: 0,
      total: 120
    },
    {
      name: 'South Korea',
      mammals: 12,
      birds: 34,
      reptiles: 3,
      amphibians: 5,
      fish: 69,
      molluscs: 3,
      otherInvertebrates: 8,
      plants: 38,
      fungi: 3,
      chromists: 0,
      total: 175
    },
    {
      name: 'Macao',
      mammals: 0,
      birds: 4,
      reptiles: 1,
      amphibians: 0,
      fish: 21,
      molluscs: 0,
      otherInvertebrates: 1,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 27
    },
    {
      name: 'Mongolia',
      mammals: 11,
      birds: 24,
      reptiles: 0,
      amphibians: 0,
      fish: 1,
      molluscs: 0,
      otherInvertebrates: 2,
      plants: 1,
      fungi: 4,
      chromists: 0,
      total: 43
    },
    {
      name: 'Taiwan',
      mammals: 12,
      birds: 24,
      reptiles: 14,
      amphibians: 10,
      fish: 144,
      molluscs: 2,
      otherInvertebrates: 129,
      plants: 88,
      fungi: 2,
      chromists: 0,
      total: 425
    },
    {
      name: 'Åland Islands',
      mammals: 0,
      birds: 0,
      reptiles: 0,
      amphibians: 0,
      fish: 0,
      molluscs: 0,
      otherInvertebrates: 0,
      plants: 1,
      fungi: 0,
      chromists: 0,
      total: 1
    },
    {
      name: 'Albania',
      mammals: 4,
      birds: 8,
      reptiles: 5,
      amphibians: 2,
      fish: 59,
      molluscs: 51,
      otherInvertebrates: 23,
      plants: 5,
      fungi: 0,
      chromists: 0,
      total: 157
    },
    {
      name: 'Andorra',
      mammals: 2,
      birds: 3,
      reptiles: 1,
      amphibians: 0,
      fish: 0,
      molluscs: 3,
      otherInvertebrates: 5,
      plants: 1,
      fungi: 0,
      chromists: 0,
      total: 15
    },
    {
      name: 'Austria',
      mammals: 5,
      birds: 13,
      reptiles: 1,
      amphibians: 0,
      fish: 13,
      molluscs: 43,
      otherInvertebrates: 64,
      plants: 38,
      fungi: 50,
      chromists: 0,
      total: 227
    },
    {
      name: 'Belgium',
      mammals: 3,
      birds: 8,
      reptiles: 0,
      amphibians: 0,
      fish: 21,
      molluscs: 6,
      otherInvertebrates: 16,
      plants: 3,
      fungi: 23,
      chromists: 0,
      total: 80
    },
    {
      name: 'Bosnia and Herzegovina',
      mammals: 5,
      birds: 9,
      reptiles: 3,
      amphibians: 2,
      fish: 51,
      molluscs: 19,
      otherInvertebrates: 38,
      plants: 5,
      fungi: 1,
      chromists: 0,
      total: 133
    },
    {
      name: 'Bulgaria',
      mammals: 10,
      birds: 18,
      reptiles: 2,
      amphibians: 1,
      fish: 21,
      molluscs: 32,
      otherInvertebrates: 40,
      plants: 13,
      fungi: 9,
      chromists: 0,
      total: 146
    },
    {
      name: 'Croatia',
      mammals: 10,
      birds: 16,
      reptiles: 4,
      amphibians: 2,
      fish: 80,
      molluscs: 50,
      otherInvertebrates: 45,
      plants: 11,
      fungi: 26,
      chromists: 0,
      total: 244
    },
    {
      name: 'Czechia',
      mammals: 4,
      birds: 10,
      reptiles: 0,
      amphibians: 0,
      fish: 2,
      molluscs: 6,
      otherInvertebrates: 41,
      plants: 31,
      fungi: 33,
      chromists: 0,
      total: 127
    },
    {
      name: 'Denmark',
      mammals: 2,
      birds: 10,
      reptiles: 0,
      amphibians: 0,
      fish: 27,
      molluscs: 5,
      otherInvertebrates: 15,
      plants: 2,
      fungi: 30,
      chromists: 0,
      total: 91
    },
    {
      name: 'Estonia',
      mammals: 1,
      birds: 11,
      reptiles: 0,
      amphibians: 0,
      fish: 4,
      molluscs: 4,
      otherInvertebrates: 6,
      plants: 2,
      fungi: 28,
      chromists: 0,
      total: 56
    },
    {
      name: 'Faroe Islands',
      mammals: 4,
      birds: 5,
      reptiles: 0,
      amphibians: 0,
      fish: 21,
      molluscs: 0,
      otherInvertebrates: 0,
      plants: 1,
      fungi: 4,
      chromists: 0,
      total: 35
    },
    {
      name: 'Finland',
      mammals: 2,
      birds: 12,
      reptiles: 0,
      amphibians: 0,
      fish: 5,
      molluscs: 3,
      otherInvertebrates: 12,
      plants: 4,
      fungi: 30,
      chromists: 0,
      total: 68
    },
    {
      name: 'France',
      mammals: 13,
      birds: 19,
      reptiles: 5,
      amphibians: 4,
      fish: 76,
      molluscs: 95,
      otherInvertebrates: 130,
      plants: 65,
      fungi: 59,
      chromists: 0,
      total: 466
    },
    {
      name: 'Germany',
      mammals: 7,
      birds: 12,
      reptiles: 0,
      amphibians: 0,
      fish: 35,
      molluscs: 30,
      otherInvertebrates: 68,
      plants: 51,
      fungi: 53,
      chromists: 0,
      total: 256
    },
    {
      name: 'Gibraltar',
      mammals: 4,
      birds: 10,
      reptiles: 0,
      amphibians: 1,
      fish: 34,
      molluscs: 5,
      otherInvertebrates: 4,
      plants: 1,
      fungi: 0,
      chromists: 0,
      total: 59
    },
    {
      name: 'Greece',
      mammals: 12,
      birds: 19,
      reptiles: 10,
      amphibians: 5,
      fish: 97,
      molluscs: 179,
      otherInvertebrates: 184,
      plants: 86,
      fungi: 9,
      chromists: 0,
      total: 601
    },
    {
      name: 'Greenland',
      mammals: 9,
      birds: 6,
      reptiles: 0,
      amphibians: 0,
      fish: 13,
      molluscs: 0,
      otherInvertebrates: 0,
      plants: 1,
      fungi: 2,
      chromists: 0,
      total: 31
    },
    {
      name: 'Guernsey',
      mammals: 0,
      birds: 0,
      reptiles: 0,
      amphibians: 0,
      fish: 15,
      molluscs: 1,
      otherInvertebrates: 0,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 16
    },
    {
      name: 'Holy See (Vatican City State)',
      mammals: 2,
      birds: 0,
      reptiles: 0,
      amphibians: 0,
      fish: 0,
      molluscs: 0,
      otherInvertebrates: 0,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 2
    },
    {
      name: 'Hungary',
      mammals: 6,
      birds: 14,
      reptiles: 1,
      amphibians: 0,
      fish: 11,
      molluscs: 8,
      otherInvertebrates: 38,
      plants: 45,
      fungi: 20,
      chromists: 0,
      total: 143
    },
    {
      name: 'Iceland',
      mammals: 6,
      birds: 7,
      reptiles: 0,
      amphibians: 0,
      fish: 21,
      molluscs: 0,
      otherInvertebrates: 1,
      plants: 0,
      fungi: 6,
      chromists: 0,
      total: 41
    },
    {
      name: 'Ireland',
      mammals: 4,
      birds: 9,
      reptiles: 1,
      amphibians: 0,
      fish: 43,
      molluscs: 4,
      otherInvertebrates: 3,
      plants: 8,
      fungi: 20,
      chromists: 0,
      total: 92
    },
    {
      name: 'Isle of Man',
      mammals: 0,
      birds: 0,
      reptiles: 0,
      amphibians: 0,
      fish: 9,
      molluscs: 0,
      otherInvertebrates: 0,
      plants: 1,
      fungi: 0,
      chromists: 0,
      total: 10
    },
    {
      name: 'Italy',
      mammals: 9,
      birds: 18,
      reptiles: 4,
      amphibians: 11,
      fish: 72,
      molluscs: 86,
      otherInvertebrates: 189,
      plants: 122,
      fungi: 47,
      chromists: 0,
      total: 558
    },
    {
      name: 'Jersey',
      mammals: 0,
      birds: 0,
      reptiles: 0,
      amphibians: 0,
      fish: 15,
      molluscs: 2,
      otherInvertebrates: 0,
      plants: 0,
      fungi: 1,
      chromists: 0,
      total: 18
    },
    {
      name: 'Latvia',
      mammals: 1,
      birds: 13,
      reptiles: 0,
      amphibians: 0,
      fish: 6,
      molluscs: 4,
      otherInvertebrates: 12,
      plants: 1,
      fungi: 10,
      chromists: 0,
      total: 47
    },
    {
      name: 'Liechtenstein',
      mammals: 0,
      birds: 3,
      reptiles: 0,
      amphibians: 0,
      fish: 0,
      molluscs: 2,
      otherInvertebrates: 5,
      plants: 1,
      fungi: 4,
      chromists: 0,
      total: 15
    },
    {
      name: 'Lithuania',
      mammals: 1,
      birds: 11,
      reptiles: 0,
      amphibians: 0,
      fish: 6,
      molluscs: 3,
      otherInvertebrates: 7,
      plants: 2,
      fungi: 14,
      chromists: 0,
      total: 44
    },
    {
      name: 'Luxembourg',
      mammals: 0,
      birds: 3,
      reptiles: 0,
      amphibians: 0,
      fish: 1,
      molluscs: 5,
      otherInvertebrates: 3,
      plants: 3,
      fungi: 2,
      chromists: 0,
      total: 17
    },
    {
      name: 'Malta',
      mammals: 3,
      birds: 6,
      reptiles: 1,
      amphibians: 0,
      fish: 43,
      molluscs: 5,
      otherInvertebrates: 6,
      plants: 4,
      fungi: 1,
      chromists: 0,
      total: 69
    },
    {
      name: 'Monaco',
      mammals: 4,
      birds: 0,
      reptiles: 0,
      amphibians: 0,
      fish: 37,
      molluscs: 2,
      otherInvertebrates: 3,
      plants: 2,
      fungi: 0,
      chromists: 0,
      total: 48
    },
    {
      name: 'Montenegro',
      mammals: 7,
      birds: 13,
      reptiles: 4,
      amphibians: 2,
      fish: 51,
      molluscs: 23,
      otherInvertebrates: 55,
      plants: 6,
      fungi: 4,
      chromists: 0,
      total: 165
    },
    {
      name: 'Netherlands',
      mammals: 4,
      birds: 10,
      reptiles: 0,
      amphibians: 0,
      fish: 23,
      molluscs: 5,
      otherInvertebrates: 12,
      plants: 3,
      fungi: 26,
      chromists: 0,
      total: 83
    },
    {
      name: 'North Macedonia',
      mammals: 7,
      birds: 13,
      reptiles: 2,
      amphibians: 1,
      fish: 13,
      molluscs: 70,
      otherInvertebrates: 28,
      plants: 6,
      fungi: 7,
      chromists: 0,
      total: 147
    },
    {
      name: 'Norway',
      mammals: 8,
      birds: 11,
      reptiles: 0,
      amphibians: 0,
      fish: 33,
      molluscs: 3,
      otherInvertebrates: 12,
      plants: 20,
      fungi: 48,
      chromists: 0,
      total: 135
    },
    {
      name: 'Poland',
      mammals: 4,
      birds: 13,
      reptiles: 0,
      amphibians: 0,
      fish: 9,
      molluscs: 7,
      otherInvertebrates: 35,
      plants: 16,
      fungi: 29,
      chromists: 0,
      total: 113
    },
    {
      name: 'Portugal',
      mammals: 15,
      birds: 17,
      reptiles: 5,
      amphibians: 2,
      fish: 88,
      molluscs: 87,
      otherInvertebrates: 169,
      plants: 145,
      fungi: 12,
      chromists: 0,
      total: 540
    },
    {
      name: 'Romania',
      mammals: 14,
      birds: 18,
      reptiles: 2,
      amphibians: 0,
      fish: 21,
      molluscs: 25,
      otherInvertebrates: 67,
      plants: 14,
      fungi: 9,
      chromists: 0,
      total: 170
    },
    {
      name: 'San Marino',
      mammals: 1,
      birds: 1,
      reptiles: 0,
      amphibians: 0,
      fish: 2,
      molluscs: 1,
      otherInvertebrates: 0,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 5
    },
    {
      name: 'Serbia',
      mammals: 8,
      birds: 14,
      reptiles: 1,
      amphibians: 1,
      fish: 14,
      molluscs: 8,
      otherInvertebrates: 59,
      plants: 9,
      fungi: 7,
      chromists: 0,
      total: 121
    },
    {
      name: 'Slovakia',
      mammals: 5,
      birds: 13,
      reptiles: 0,
      amphibians: 0,
      fish: 7,
      molluscs: 7,
      otherInvertebrates: 37,
      plants: 32,
      fungi: 33,
      chromists: 0,
      total: 134
    },
    {
      name: 'Slovenia',
      mammals: 8,
      birds: 11,
      reptiles: 2,
      amphibians: 2,
      fish: 52,
      molluscs: 33,
      otherInvertebrates: 69,
      plants: 13,
      fungi: 21,
      chromists: 0,
      total: 211
    },
    {
      name: 'Spain',
      mammals: 18,
      birds: 23,
      reptiles: 19,
      amphibians: 7,
      fish: 98,
      molluscs: 167,
      otherInvertebrates: 185,
      plants: 290,
      fungi: 47,
      chromists: 0,
      total: 854
    },
    {
      name: 'Svalbard and Jan Mayen',
      mammals: 4,
      birds: 4,
      reptiles: 0,
      amphibians: 0,
      fish: 5,
      molluscs: 0,
      otherInvertebrates: 0,
      plants: 1,
      fungi: 0,
      chromists: 0,
      total: 14
    },
    {
      name: 'Sweden',
      mammals: 1,
      birds: 12,
      reptiles: 0,
      amphibians: 0,
      fish: 25,
      molluscs: 4,
      otherInvertebrates: 15,
      plants: 12,
      fungi: 48,
      chromists: 0,
      total: 117
    },
    {
      name: 'Switzerland',
      mammals: 5,
      birds: 10,
      reptiles: 0,
      amphibians: 1,
      fish: 9,
      molluscs: 10,
      otherInvertebrates: 73,
      plants: 20,
      fungi: 43,
      chromists: 0,
      total: 171
    },
    {
      name: 'United Kingdom',
      mammals: 4,
      birds: 11,
      reptiles: 1,
      amphibians: 0,
      fish: 61,
      molluscs: 7,
      otherInvertebrates: 23,
      plants: 53,
      fungi: 40,
      chromists: 0,
      total: 200
    },
    {
      name: 'Belize',
      mammals: 10,
      birds: 8,
      reptiles: 8,
      amphibians: 2,
      fish: 67,
      molluscs: 0,
      otherInvertebrates: 27,
      plants: 90,
      fungi: 0,
      chromists: 0,
      total: 212
    },
    {
      name: 'Costa Rica',
      mammals: 12,
      birds: 25,
      reptiles: 16,
      amphibians: 55,
      fish: 145,
      molluscs: 2,
      otherInvertebrates: 53,
      plants: 342,
      fungi: 5,
      chromists: 0,
      total: 655
    },
    {
      name: 'El Salvador',
      mammals: 6,
      birds: 9,
      reptiles: 10,
      amphibians: 8,
      fish: 52,
      molluscs: 0,
      otherInvertebrates: 10,
      plants: 82,
      fungi: 0,
      chromists: 0,
      total: 177
    },
    {
      name: 'Guatemala',
      mammals: 16,
      birds: 19,
      reptiles: 39,
      amphibians: 96,
      fish: 97,
      molluscs: 2,
      otherInvertebrates: 34,
      plants: 394,
      fungi: 1,
      chromists: 0,
      total: 698
    },
    {
      name: 'Honduras',
      mammals: 10,
      birds: 18,
      reptiles: 60,
      amphibians: 69,
      fish: 89,
      molluscs: 0,
      otherInvertebrates: 36,
      plants: 196,
      fungi: 2,
      chromists: 0,
      total: 480
    },
    {
      name: 'Mexico',
      mammals: 97,
      birds: 68,
      reptiles: 105,
      amphibians: 232,
      fish: 308,
      molluscs: 14,
      otherInvertebrates: 129,
      plants: 1478,
      fungi: 6,
      chromists: 0,
      total: 2437
    },
    {
      name: 'Nicaragua',
      mammals: 9,
      birds: 19,
      reptiles: 11,
      amphibians: 11,
      fish: 97,
      molluscs: 2,
      otherInvertebrates: 33,
      plants: 121,
      fungi: 0,
      chromists: 0,
      total: 303
    },
    {
      name: 'Panama',
      mammals: 18,
      birds: 19,
      reptiles: 16,
      amphibians: 77,
      fish: 128,
      molluscs: 0,
      otherInvertebrates: 39,
      plants: 262,
      fungi: 2,
      chromists: 0,
      total: 561
    },
    {
      name: 'Algeria',
      mammals: 15,
      birds: 19,
      reptiles: 8,
      amphibians: 2,
      fish: 65,
      molluscs: 12,
      otherInvertebrates: 32,
      plants: 31,
      fungi: 2,
      chromists: 0,
      total: 186
    },
    {
      name: 'Egypt',
      mammals: 19,
      birds: 16,
      reptiles: 12,
      amphibians: 0,
      fish: 89,
      molluscs: 2,
      otherInvertebrates: 58,
      plants: 13,
      fungi: 0,
      chromists: 0,
      total: 209
    },
    {
      name: 'Libya',
      mammals: 11,
      birds: 10,
      reptiles: 6,
      amphibians: 0,
      fish: 51,
      molluscs: 1,
      otherInvertebrates: 5,
      plants: 8,
      fungi: 0,
      chromists: 0,
      total: 92
    },
    {
      name: 'Morocco',
      mammals: 19,
      birds: 21,
      reptiles: 13,
      amphibians: 3,
      fish: 85,
      molluscs: 39,
      otherInvertebrates: 34,
      plants: 58,
      fungi: 6,
      chromists: 0,
      total: 278
    },
    {
      name: 'Tunisia',
      mammals: 15,
      birds: 13,
      reptiles: 6,
      amphibians: 0,
      fish: 58,
      molluscs: 9,
      otherInvertebrates: 14,
      plants: 12,
      fungi: 2,
      chromists: 0,
      total: 129
    },
    {
      name: 'Western Sahara',
      mammals: 11,
      birds: 6,
      reptiles: 1,
      amphibians: 0,
      fish: 63,
      molluscs: 2,
      otherInvertebrates: 1,
      plants: 1,
      fungi: 0,
      chromists: 0,
      total: 85
    },
    {
      name: 'Canada',
      mammals: 18,
      birds: 22,
      reptiles: 6,
      amphibians: 1,
      fish: 53,
      molluscs: 21,
      otherInvertebrates: 24,
      plants: 21,
      fungi: 29,
      chromists: 0,
      total: 195
    },
    {
      name: 'Saint Pierre and Miquelon',
      mammals: 4,
      birds: 9,
      reptiles: 0,
      amphibians: 0,
      fish: 13,
      molluscs: 0,
      otherInvertebrates: 0,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 26
    },
    {
      name: 'United States',
      mammals: 43,
      birds: 87,
      reptiles: 38,
      amphibians: 59,
      fish: 284,
      molluscs: 320,
      otherInvertebrates: 347,
      plants: 695,
      fungi: 87,
      chromists: 0,
      total: 1960
    },
    {
      name: 'Belarus',
      mammals: 4,
      birds: 11,
      reptiles: 0,
      amphibians: 0,
      fish: 3,
      molluscs: 3,
      otherInvertebrates: 9,
      plants: 2,
      fungi: 4,
      chromists: 0,
      total: 36
    },
    {
      name: 'Moldova',
      mammals: 6,
      birds: 12,
      reptiles: 2,
      amphibians: 0,
      fish: 8,
      molluscs: 2,
      otherInvertebrates: 4,
      plants: 2,
      fungi: 0,
      chromists: 0,
      total: 36
    },
    {
      name: 'Russia',
      mammals: 33,
      birds: 57,
      reptiles: 8,
      amphibians: 0,
      fish: 65,
      molluscs: 7,
      otherInvertebrates: 37,
      plants: 68,
      fungi: 41,
      chromists: 0,
      total: 316
    },
    {
      name: 'Ukraine',
      mammals: 13,
      birds: 18,
      reptiles: 1,
      amphibians: 0,
      fish: 25,
      molluscs: 9,
      otherInvertebrates: 38,
      plants: 29,
      fungi: 14,
      chromists: 0,
      total: 147
    },
    {
      name: 'American Samoa',
      mammals: 1,
      birds: 6,
      reptiles: 6,
      amphibians: 0,
      fish: 24,
      molluscs: 5,
      otherInvertebrates: 61,
      plants: 2,
      fungi: 0,
      chromists: 0,
      total: 105
    },
    {
      name: 'Australia',
      mammals: 69,
      birds: 66,
      reptiles: 76,
      amphibians: 46,
      fish: 216,
      molluscs: 182,
      otherInvertebrates: 412,
      plants: 791,
      fungi: 13,
      chromists: 0,
      total: 1871
    },
    {
      name: 'Christmas Island',
      mammals: 2,
      birds: 5,
      reptiles: 3,
      amphibians: 0,
      fish: 22,
      molluscs: 0,
      otherInvertebrates: 20,
      plants: 2,
      fungi: 0,
      chromists: 0,
      total: 54
    },
    {
      name: 'Cocos (Keeling) Islands',
      mammals: 2,
      birds: 1,
      reptiles: 1,
      amphibians: 0,
      fish: 24,
      molluscs: 0,
      otherInvertebrates: 21,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 49
    },
    {
      name: 'Cook Islands',
      mammals: 1,
      birds: 11,
      reptiles: 3,
      amphibians: 0,
      fish: 20,
      molluscs: 1,
      otherInvertebrates: 33,
      plants: 13,
      fungi: 0,
      chromists: 0,
      total: 82
    },
    {
      name: 'Fiji',
      mammals: 7,
      birds: 14,
      reptiles: 16,
      amphibians: 0,
      fish: 33,
      molluscs: 84,
      otherInvertebrates: 108,
      plants: 95,
      fungi: 0,
      chromists: 0,
      total: 357
    },
    {
      name: 'French Polynesia',
      mammals: 0,
      birds: 35,
      reptiles: 3,
      amphibians: 0,
      fish: 37,
      molluscs: 33,
      otherInvertebrates: 35,
      plants: 56,
      fungi: 0,
      chromists: 0,
      total: 199
    },
    {
      name: 'Guam',
      mammals: 3,
      birds: 12,
      reptiles: 5,
      amphibians: 0,
      fish: 25,
      molluscs: 6,
      otherInvertebrates: 55,
      plants: 5,
      fungi: 0,
      chromists: 0,
      total: 111
    },
    {
      name: 'Kiribati',
      mammals: 2,
      birds: 6,
      reptiles: 2,
      amphibians: 0,
      fish: 24,
      molluscs: 1,
      otherInvertebrates: 81,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 116
    },
    {
      name: 'Marshall Islands',
      mammals: 1,
      birds: 5,
      reptiles: 4,
      amphibians: 0,
      fish: 27,
      molluscs: 1,
      otherInvertebrates: 73,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 111
    },
    {
      name: 'Micronesia',
      mammals: 6,
      birds: 13,
      reptiles: 8,
      amphibians: 0,
      fish: 32,
      molluscs: 3,
      otherInvertebrates: 118,
      plants: 8,
      fungi: 0,
      chromists: 0,
      total: 188
    },
    {
      name: 'Nauru',
      mammals: 2,
      birds: 3,
      reptiles: 0,
      amphibians: 0,
      fish: 20,
      molluscs: 0,
      otherInvertebrates: 69,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 94
    },
    {
      name: 'New Caledonia',
      mammals: 8,
      birds: 18,
      reptiles: 72,
      amphibians: 0,
      fish: 51,
      molluscs: 28,
      otherInvertebrates: 102,
      plants: 719,
      fungi: 4,
      chromists: 0,
      total: 1002
    },
    {
      name: 'New Zealand',
      mammals: 9,
      birds: 66,
      reptiles: 52,
      amphibians: 3,
      fish: 48,
      molluscs: 39,
      otherInvertebrates: 26,
      plants: 21,
      fungi: 13,
      chromists: 0,
      total: 277
    },
    {
      name: 'Niue',
      mammals: 1,
      birds: 7,
      reptiles: 4,
      amphibians: 0,
      fish: 18,
      molluscs: 0,
      otherInvertebrates: 31,
      plants: 2,
      fungi: 0,
      chromists: 0,
      total: 63
    },
    {
      name: 'Norfolk Island',
      mammals: 1,
      birds: 11,
      reptiles: 2,
      amphibians: 0,
      fish: 14,
      molluscs: 12,
      otherInvertebrates: 12,
      plants: 2,
      fungi: 0,
      chromists: 0,
      total: 54
    },
    {
      name: 'Northern Mariana Islands',
      mammals: 3,
      birds: 13,
      reptiles: 4,
      amphibians: 0,
      fish: 24,
      molluscs: 4,
      otherInvertebrates: 54,
      plants: 6,
      fungi: 0,
      chromists: 0,
      total: 108
    },
    {
      name: 'Palau',
      mammals: 5,
      birds: 6,
      reptiles: 4,
      amphibians: 0,
      fish: 29,
      molluscs: 40,
      otherInvertebrates: 109,
      plants: 5,
      fungi: 0,
      chromists: 0,
      total: 198
    },
    {
      name: 'Papua New Guinea',
      mammals: 51,
      birds: 37,
      reptiles: 13,
      amphibians: 28,
      fish: 134,
      molluscs: 24,
      otherInvertebrates: 182,
      plants: 661,
      fungi: 3,
      chromists: 0,
      total: 1133
    },
    {
      name: 'Pitcairn',
      mammals: 0,
      birds: 9,
      reptiles: 0,
      amphibians: 0,
      fish: 12,
      molluscs: 5,
      otherInvertebrates: 12,
      plants: 10,
      fungi: 0,
      chromists: 0,
      total: 48
    },
    {
      name: 'Samoa',
      mammals: 2,
      birds: 5,
      reptiles: 4,
      amphibians: 0,
      fish: 26,
      molluscs: 4,
      otherInvertebrates: 64,
      plants: 11,
      fungi: 0,
      chromists: 0,
      total: 116
    },
    {
      name: 'Solomon Islands',
      mammals: 24,
      birds: 20,
      reptiles: 7,
      amphibians: 3,
      fish: 43,
      molluscs: 2,
      otherInvertebrates: 148,
      plants: 56,
      fungi: 0,
      chromists: 0,
      total: 303
    },
    {
      name: 'Tokelau',
      mammals: 0,
      birds: 0,
      reptiles: 3,
      amphibians: 0,
      fish: 22,
      molluscs: 0,
      otherInvertebrates: 36,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 61
    },
    {
      name: 'Tonga',
      mammals: 2,
      birds: 5,
      reptiles: 4,
      amphibians: 0,
      fish: 29,
      molluscs: 25,
      otherInvertebrates: 45,
      plants: 10,
      fungi: 0,
      chromists: 0,
      total: 120
    },
    {
      name: 'Tuvalu',
      mammals: 2,
      birds: 0,
      reptiles: 4,
      amphibians: 0,
      fish: 21,
      molluscs: 1,
      otherInvertebrates: 78,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 106
    },
    {
      name: 'United States Minor Outlying Islands',
      mammals: 0,
      birds: 10,
      reptiles: 1,
      amphibians: 0,
      fish: 18,
      molluscs: 0,
      otherInvertebrates: 38,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 67
    },
    {
      name: 'Vanuatu',
      mammals: 8,
      birds: 8,
      reptiles: 5,
      amphibians: 0,
      fish: 30,
      molluscs: 3,
      otherInvertebrates: 89,
      plants: 15,
      fungi: 0,
      chromists: 0,
      total: 158
    },
    {
      name: 'Wallis and Futuna',
      mammals: 0,
      birds: 7,
      reptiles: 2,
      amphibians: 0,
      fish: 21,
      molluscs: 0,
      otherInvertebrates: 64,
      plants: 3,
      fungi: 0,
      chromists: 0,
      total: 97
    },
    {
      name: 'Argentina',
      mammals: 39,
      birds: 54,
      reptiles: 29,
      amphibians: 33,
      fish: 106,
      molluscs: 0,
      otherInvertebrates: 19,
      plants: 74,
      fungi: 21,
      chromists: 0,
      total: 375
    },
    {
      name: 'Bolivia',
      mammals: 24,
      birds: 47,
      reptiles: 14,
      amphibians: 53,
      fish: 13,
      molluscs: 2,
      otherInvertebrates: 1,
      plants: 298,
      fungi: 0,
      chromists: 0,
      total: 452
    },
    {
      name: 'Brazil',
      mammals: 97,
      birds: 153,
      reptiles: 94,
      amphibians: 41,
      fish: 367,
      molluscs: 22,
      otherInvertebrates: 82,
      plants: 1337,
      fungi: 36,
      chromists: 0,
      total: 2229
    },
    {
      name: 'Chile',
      mammals: 18,
      birds: 34,
      reptiles: 28,
      amphibians: 34,
      fish: 43,
      molluscs: 1,
      otherInvertebrates: 13,
      plants: 93,
      fungi: 19,
      chromists: 0,
      total: 283
    },
    {
      name: 'Colombia',
      mammals: 63,
      birds: 97,
      reptiles: 39,
      amphibians: 292,
      fish: 183,
      molluscs: 4,
      otherInvertebrates: 77,
      plants: 950,
      fungi: 6,
      chromists: 0,
      total: 1711
    },
    {
      name: 'Ecuador',
      mammals: 49,
      birds: 83,
      reptiles: 93,
      amphibians: 247,
      fish: 100,
      molluscs: 49,
      otherInvertebrates: 27,
      plants: 2036,
      fungi: 2,
      chromists: 6,
      total: 2692
    },
    {
      name: 'Falkland Islands',
      mammals: 4,
      birds: 8,
      reptiles: 0,
      amphibians: 0,
      fish: 7,
      molluscs: 0,
      otherInvertebrates: 0,
      plants: 5,
      fungi: 6,
      chromists: 0,
      total: 30
    },
    {
      name: 'French Guiana',
      mammals: 8,
      birds: 5,
      reptiles: 7,
      amphibians: 5,
      fish: 61,
      molluscs: 0,
      otherInvertebrates: 1,
      plants: 43,
      fungi: 0,
      chromists: 0,
      total: 130
    },
    {
      name: 'Guyana',
      mammals: 11,
      birds: 11,
      reptiles: 6,
      amphibians: 21,
      fish: 68,
      molluscs: 0,
      otherInvertebrates: 1,
      plants: 83,
      fungi: 1,
      chromists: 0,
      total: 202
    },
    {
      name: 'Paraguay',
      mammals: 11,
      birds: 27,
      reptiles: 12,
      amphibians: 1,
      fish: 6,
      molluscs: 0,
      otherInvertebrates: 2,
      plants: 22,
      fungi: 2,
      chromists: 0,
      total: 83
    },
    {
      name: 'Peru',
      mammals: 51,
      birds: 92,
      reptiles: 33,
      amphibians: 139,
      fish: 90,
      molluscs: 4,
      otherInvertebrates: 7,
      plants: 568,
      fungi: 1,
      chromists: 0,
      total: 985
    },
    {
      name: 'Suriname',
      mammals: 9,
      birds: 5,
      reptiles: 6,
      amphibians: 1,
      fish: 63,
      molluscs: 0,
      otherInvertebrates: 1,
      plants: 44,
      fungi: 0,
      chromists: 0,
      total: 129
    },
    {
      name: 'Uruguay',
      mammals: 9,
      birds: 22,
      reptiles: 7,
      amphibians: 4,
      fish: 69,
      molluscs: 0,
      otherInvertebrates: 3,
      plants: 22,
      fungi: 1,
      chromists: 0,
      total: 137
    },
    {
      name: 'Venezuela',
      mammals: 37,
      birds: 40,
      reptiles: 32,
      amphibians: 128,
      fish: 105,
      molluscs: 1,
      otherInvertebrates: 59,
      plants: 464,
      fungi: 2,
      chromists: 0,
      total: 868
    },
    {
      name: 'Bangladesh',
      mammals: 39,
      birds: 36,
      reptiles: 30,
      amphibians: 0,
      fish: 77,
      molluscs: 0,
      otherInvertebrates: 7,
      plants: 35,
      fungi: 0,
      chromists: 0,
      total: 224
    },
    {
      name: 'Bhutan',
      mammals: 28,
      birds: 19,
      reptiles: 10,
      amphibians: 1,
      fish: 5,
      molluscs: 0,
      otherInvertebrates: 1,
      plants: 57,
      fungi: 2,
      chromists: 0,
      total: 123
    },
    {
      name: 'British Indian Ocean Territory',
      mammals: 0,
      birds: 1,
      reptiles: 2,
      amphibians: 0,
      fish: 25,
      molluscs: 0,
      otherInvertebrates: 70,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 98
    },
    {
      name: 'Brunei',
      mammals: 38,
      birds: 40,
      reptiles: 10,
      amphibians: 2,
      fish: 61,
      molluscs: 0,
      otherInvertebrates: 18,
      plants: 190,
      fungi: 0,
      chromists: 0,
      total: 359
    },
    {
      name: 'Cambodia',
      mammals: 45,
      birds: 32,
      reptiles: 23,
      amphibians: 8,
      fish: 94,
      molluscs: 1,
      otherInvertebrates: 81,
      plants: 55,
      fungi: 0,
      chromists: 0,
      total: 339
    },
    {
      name: 'Disputed Territory',
      mammals: 0,
      birds: 0,
      reptiles: 0,
      amphibians: 0,
      fish: 20,
      molluscs: 0,
      otherInvertebrates: 1,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 21
    },
    {
      name: 'India',
      mammals: 99,
      birds: 91,
      reptiles: 106,
      amphibians: 83,
      fish: 292,
      molluscs: 7,
      otherInvertebrates: 135,
      plants: 539,
      fungi: 3,
      chromists: 0,
      total: 1355
    },
    {
      name: 'Indonesia',
      mammals: 213,
      birds: 161,
      reptiles: 77,
      amphibians: 30,
      fish: 369,
      molluscs: 42,
      otherInvertebrates: 341,
      plants: 1047,
      fungi: 2,
      chromists: 0,
      total: 2282
    },
    {
      name: 'Laos',
      mammals: 49,
      birds: 30,
      reptiles: 35,
      amphibians: 10,
      fish: 56,
      molluscs: 16,
      otherInvertebrates: 7,
      plants: 88,
      fungi: 1,
      chromists: 0,
      total: 292
    },
    {
      name: 'Malaysia',
      mammals: 81,
      birds: 68,
      reptiles: 60,
      amphibians: 39,
      fish: 182,
      molluscs: 45,
      otherInvertebrates: 280,
      plants: 1331,
      fungi: 4,
      chromists: 0,
      total: 2090
    },
    {
      name: 'Maldives',
      mammals: 2,
      birds: 0,
      reptiles: 3,
      amphibians: 0,
      fish: 41,
      molluscs: 0,
      otherInvertebrates: 45,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 91
    },
    {
      name: 'Myanmar',
      mammals: 54,
      birds: 58,
      reptiles: 38,
      amphibians: 5,
      fish: 99,
      molluscs: 3,
      otherInvertebrates: 75,
      plants: 113,
      fungi: 0,
      chromists: 0,
      total: 445
    },
    {
      name: 'Nepal',
      mammals: 30,
      birds: 38,
      reptiles: 17,
      amphibians: 4,
      fish: 11,
      molluscs: 1,
      otherInvertebrates: 2,
      plants: 26,
      fungi: 2,
      chromists: 0,
      total: 131
    },
    {
      name: 'Philippines',
      mammals: 43,
      birds: 91,
      reptiles: 37,
      amphibians: 27,
      fish: 145,
      molluscs: 3,
      otherInvertebrates: 347,
      plants: 930,
      fungi: 1,
      chromists: 0,
      total: 1624
    },
    {
      name: 'Singapore',
      mammals: 18,
      birds: 24,
      reptiles: 8,
      amphibians: 0,
      fish: 69,
      molluscs: 1,
      otherInvertebrates: 176,
      plants: 69,
      fungi: 0,
      chromists: 0,
      total: 365
    },
    {
      name: 'Sri Lanka',
      mammals: 30,
      birds: 14,
      reptiles: 113,
      amphibians: 71,
      fish: 137,
      molluscs: 0,
      otherInvertebrates: 153,
      plants: 302,
      fungi: 0,
      chromists: 0,
      total: 820
    },
    {
      name: 'Thailand',
      mammals: 65,
      birds: 67,
      reptiles: 42,
      amphibians: 12,
      fish: 154,
      molluscs: 16,
      otherInvertebrates: 198,
      plants: 247,
      fungi: 1,
      chromists: 0,
      total: 802
    },
    {
      name: 'Timor-Leste',
      mammals: 6,
      birds: 7,
      reptiles: 3,
      amphibians: 0,
      fish: 35,
      molluscs: 0,
      otherInvertebrates: 1,
      plants: 7,
      fungi: 0,
      chromists: 0,
      total: 59
    },
    {
      name: 'Vietnam',
      mammals: 64,
      birds: 51,
      reptiles: 75,
      amphibians: 75,
      fish: 142,
      molluscs: 30,
      otherInvertebrates: 161,
      plants: 335,
      fungi: 0,
      chromists: 0,
      total: 933
    },
    {
      name: 'Angola',
      mammals: 24,
      birds: 33,
      reptiles: 10,
      amphibians: 0,
      fish: 82,
      molluscs: 7,
      otherInvertebrates: 4,
      plants: 69,
      fungi: 0,
      chromists: 0,
      total: 229
    },
    {
      name: 'Benin',
      mammals: 16,
      birds: 14,
      reptiles: 8,
      amphibians: 1,
      fish: 73,
      molluscs: 3,
      otherInvertebrates: 1,
      plants: 17,
      fungi: 2,
      chromists: 0,
      total: 135
    },
    {
      name: 'Botswana',
      mammals: 11,
      birds: 18,
      reptiles: 1,
      amphibians: 0,
      fish: 2,
      molluscs: 0,
      otherInvertebrates: 0,
      plants: 3,
      fungi: 0,
      chromists: 0,
      total: 35
    },
    {
      name: 'Burkina Faso',
      mammals: 12,
      birds: 15,
      reptiles: 4,
      amphibians: 0,
      fish: 3,
      molluscs: 0,
      otherInvertebrates: 0,
      plants: 6,
      fungi: 0,
      chromists: 0,
      total: 40
    },
    {
      name: 'Burundi',
      mammals: 16,
      birds: 18,
      reptiles: 1,
      amphibians: 1,
      fish: 17,
      molluscs: 3,
      otherInvertebrates: 3,
      plants: 159,
      fungi: 0,
      chromists: 0,
      total: 218
    },
    {
      name: 'Cabo Verde',
      mammals: 4,
      birds: 6,
      reptiles: 6,
      amphibians: 0,
      fish: 47,
      molluscs: 14,
      otherInvertebrates: 1,
      plants: 51,
      fungi: 0,
      chromists: 0,
      total: 129
    },
    {
      name: 'Cameroon',
      mammals: 49,
      birds: 30,
      reptiles: 24,
      amphibians: 60,
      fish: 148,
      molluscs: 14,
      otherInvertebrates: 14,
      plants: 911,
      fungi: 0,
      chromists: 0,
      total: 1250
    },
    {
      name: 'Central African Republic',
      mammals: 20,
      birds: 18,
      reptiles: 7,
      amphibians: 0,
      fish: 3,
      molluscs: 2,
      otherInvertebrates: 0,
      plants: 51,
      fungi: 0,
      chromists: 0,
      total: 101
    },
    {
      name: 'Chad',
      mammals: 16,
      birds: 19,
      reptiles: 5,
      amphibians: 0,
      fish: 1,
      molluscs: 6,
      otherInvertebrates: 0,
      plants: 8,
      fungi: 0,
      chromists: 0,
      total: 55
    },
    {
      name: 'Comoros',
      mammals: 6,
      birds: 13,
      reptiles: 9,
      amphibians: 0,
      fish: 24,
      molluscs: 0,
      otherInvertebrates: 81,
      plants: 16,
      fungi: 0,
      chromists: 0,
      total: 149
    },
    {
      name: 'Congo',
      mammals: 18,
      birds: 6,
      reptiles: 8,
      amphibians: 1,
      fish: 83,
      molluscs: 7,
      otherInvertebrates: 0,
      plants: 112,
      fungi: 0,
      chromists: 0,
      total: 235
    },
    {
      name: 'Democratic Republic of the Congo',
      mammals: 48,
      birds: 40,
      reptiles: 18,
      amphibians: 13,
      fish: 128,
      molluscs: 45,
      otherInvertebrates: 9,
      plants: 445,
      fungi: 0,
      chromists: 0,
      total: 746
    },
    {
      name: "Côte d'Ivoire",
      mammals: 34,
      birds: 26,
      reptiles: 9,
      amphibians: 7,
      fish: 89,
      molluscs: 8,
      otherInvertebrates: 2,
      plants: 158,
      fungi: 0,
      chromists: 0,
      total: 333
    },
    {
      name: 'Djibouti',
      mammals: 11,
      birds: 15,
      reptiles: 1,
      amphibians: 0,
      fish: 41,
      molluscs: 1,
      otherInvertebrates: 56,
      plants: 4,
      fungi: 0,
      chromists: 0,
      total: 129
    },
    {
      name: 'Equatorial Guinea',
      mammals: 24,
      birds: 3,
      reptiles: 8,
      amphibians: 6,
      fish: 82,
      molluscs: 2,
      otherInvertebrates: 2,
      plants: 170,
      fungi: 0,
      chromists: 0,
      total: 297
    },
    {
      name: 'Eritrea',
      mammals: 16,
      birds: 22,
      reptiles: 7,
      amphibians: 0,
      fish: 51,
      molluscs: 1,
      otherInvertebrates: 57,
      plants: 7,
      fungi: 0,
      chromists: 0,
      total: 161
    },
    {
      name: 'Eswatini',
      mammals: 9,
      birds: 15,
      reptiles: 1,
      amphibians: 0,
      fish: 5,
      molluscs: 0,
      otherInvertebrates: 3,
      plants: 14,
      fungi: 0,
      chromists: 0,
      total: 47
    },
    {
      name: 'Ethiopia',
      mammals: 40,
      birds: 35,
      reptiles: 4,
      amphibians: 12,
      fish: 16,
      molluscs: 4,
      otherInvertebrates: 11,
      plants: 115,
      fungi: 0,
      chromists: 0,
      total: 237
    },
    {
      name: 'Gabon',
      mammals: 18,
      birds: 5,
      reptiles: 8,
      amphibians: 5,
      fish: 97,
      molluscs: 2,
      otherInvertebrates: 2,
      plants: 473,
      fungi: 0,
      chromists: 0,
      total: 610
    },
    {
      name: 'Gambia',
      mammals: 10,
      birds: 19,
      reptiles: 6,
      amphibians: 0,
      fish: 80,
      molluscs: 3,
      otherInvertebrates: 0,
      plants: 6,
      fungi: 0,
      chromists: 0,
      total: 124
    },
    {
      name: 'Ghana',
      mammals: 23,
      birds: 25,
      reptiles: 9,
      amphibians: 9,
      fish: 87,
      molluscs: 9,
      otherInvertebrates: 3,
      plants: 127,
      fungi: 0,
      chromists: 0,
      total: 292
    },
    {
      name: 'Guinea',
      mammals: 34,
      birds: 22,
      reptiles: 10,
      amphibians: 7,
      fish: 117,
      molluscs: 6,
      otherInvertebrates: 5,
      plants: 221,
      fungi: 0,
      chromists: 0,
      total: 422
    },
    {
      name: 'Guinea-Bissau',
      mammals: 15,
      birds: 15,
      reptiles: 9,
      amphibians: 0,
      fish: 71,
      molluscs: 3,
      otherInvertebrates: 0,
      plants: 12,
      fungi: 0,
      chromists: 0,
      total: 125
    },
    {
      name: 'Kenya',
      mammals: 32,
      birds: 45,
      reptiles: 16,
      amphibians: 15,
      fish: 88,
      molluscs: 20,
      otherInvertebrates: 79,
      plants: 402,
      fungi: 0,
      chromists: 0,
      total: 697
    },
    {
      name: 'Lesotho',
      mammals: 4,
      birds: 9,
      reptiles: 0,
      amphibians: 0,
      fish: 1,
      molluscs: 0,
      otherInvertebrates: 1,
      plants: 5,
      fungi: 0,
      chromists: 0,
      total: 20
    },
    {
      name: 'Liberia',
      mammals: 27,
      birds: 14,
      reptiles: 9,
      amphibians: 2,
      fish: 93,
      molluscs: 5,
      otherInvertebrates: 7,
      plants: 137,
      fungi: 0,
      chromists: 0,
      total: 294
    },
    {
      name: 'Madagascar',
      mammals: 133,
      birds: 37,
      reptiles: 139,
      amphibians: 145,
      fish: 112,
      molluscs: 35,
      otherInvertebrates: 248,
      plants: 2897,
      fungi: 1,
      chromists: 0,
      total: 3747
    },
    {
      name: 'Malawi',
      mammals: 10,
      birds: 20,
      reptiles: 4,
      amphibians: 5,
      fish: 37,
      molluscs: 7,
      otherInvertebrates: 8,
      plants: 61,
      fungi: 0,
      chromists: 0,
      total: 152
    },
    {
      name: 'Mali',
      mammals: 14,
      birds: 19,
      reptiles: 4,
      amphibians: 0,
      fish: 1,
      molluscs: 5,
      otherInvertebrates: 0,
      plants: 29,
      fungi: 0,
      chromists: 0,
      total: 72
    },
    {
      name: 'Mauritania',
      mammals: 18,
      birds: 24,
      reptiles: 6,
      amphibians: 0,
      fish: 78,
      molluscs: 3,
      otherInvertebrates: 1,
      plants: 1,
      fungi: 0,
      chromists: 0,
      total: 131
    },
    {
      name: 'Mauritius',
      mammals: 7,
      birds: 12,
      reptiles: 15,
      amphibians: 0,
      fish: 29,
      molluscs: 33,
      otherInvertebrates: 93,
      plants: 103,
      fungi: 0,
      chromists: 0,
      total: 292
    },
    {
      name: 'Mayotte',
      mammals: 2,
      birds: 3,
      reptiles: 7,
      amphibians: 1,
      fish: 22,
      molluscs: 0,
      otherInvertebrates: 77,
      plants: 14,
      fungi: 0,
      chromists: 0,
      total: 126
    },
    {
      name: 'Mozambique',
      mammals: 19,
      birds: 32,
      reptiles: 15,
      amphibians: 7,
      fish: 105,
      molluscs: 0,
      otherInvertebrates: 67,
      plants: 300,
      fungi: 0,
      chromists: 0,
      total: 545
    },
    {
      name: 'Namibia',
      mammals: 15,
      birds: 33,
      reptiles: 5,
      amphibians: 0,
      fish: 47,
      molluscs: 2,
      otherInvertebrates: 2,
      plants: 49,
      fungi: 0,
      chromists: 0,
      total: 153
    },
    {
      name: 'Niger',
      mammals: 14,
      birds: 16,
      reptiles: 4,
      amphibians: 0,
      fish: 3,
      molluscs: 2,
      otherInvertebrates: 0,
      plants: 4,
      fungi: 0,
      chromists: 0,
      total: 43
    },
    {
      name: 'Nigeria',
      mammals: 36,
      birds: 22,
      reptiles: 15,
      amphibians: 13,
      fish: 102,
      molluscs: 5,
      otherInvertebrates: 10,
      plants: 258,
      fungi: 0,
      chromists: 0,
      total: 461
    },
    {
      name: 'Réunion',
      mammals: 4,
      birds: 7,
      reptiles: 2,
      amphibians: 0,
      fish: 32,
      molluscs: 17,
      otherInvertebrates: 73,
      plants: 23,
      fungi: 0,
      chromists: 0,
      total: 158
    },
    {
      name: 'Rwanda',
      mammals: 24,
      birds: 20,
      reptiles: 4,
      amphibians: 2,
      fish: 7,
      molluscs: 0,
      otherInvertebrates: 4,
      plants: 134,
      fungi: 0,
      chromists: 0,
      total: 195
    },
    {
      name: 'Saint Helena, Ascension and Tristan da Cunha',
      mammals: 3,
      birds: 21,
      reptiles: 2,
      amphibians: 0,
      fish: 20,
      molluscs: 1,
      otherInvertebrates: 96,
      plants: 45,
      fungi: 1,
      chromists: 0,
      total: 189
    },
    {
      name: 'Sao Tome and Principe',
      mammals: 4,
      birds: 12,
      reptiles: 6,
      amphibians: 3,
      fish: 38,
      molluscs: 4,
      otherInvertebrates: 2,
      plants: 72,
      fungi: 0,
      chromists: 0,
      total: 141
    },
    {
      name: 'Senegal',
      mammals: 21,
      birds: 23,
      reptiles: 9,
      amphibians: 0,
      fish: 89,
      molluscs: 15,
      otherInvertebrates: 1,
      plants: 23,
      fungi: 0,
      chromists: 0,
      total: 181
    },
    {
      name: 'Seychelles',
      mammals: 7,
      birds: 13,
      reptiles: 12,
      amphibians: 6,
      fish: 40,
      molluscs: 37,
      otherInvertebrates: 285,
      plants: 61,
      fungi: 0,
      chromists: 0,
      total: 461
    },
    {
      name: 'Sierra Leone',
      mammals: 26,
      birds: 17,
      reptiles: 10,
      amphibians: 1,
      fish: 87,
      molluscs: 7,
      otherInvertebrates: 5,
      plants: 142,
      fungi: 0,
      chromists: 0,
      total: 295
    },
    {
      name: 'Somalia',
      mammals: 18,
      birds: 20,
      reptiles: 5,
      amphibians: 0,
      fish: 59,
      molluscs: 2,
      otherInvertebrates: 60,
      plants: 68,
      fungi: 0,
      chromists: 0,
      total: 232
    },
    {
      name: 'South Africa',
      mammals: 33,
      birds: 54,
      reptiles: 25,
      amphibians: 16,
      fish: 153,
      molluscs: 23,
      otherInvertebrates: 173,
      plants: 428,
      fungi: 0,
      chromists: 0,
      total: 905
    },
    {
      name: 'South Sudan',
      mammals: 18,
      birds: 23,
      reptiles: 5,
      amphibians: 0,
      fish: 0,
      molluscs: 0,
      otherInvertebrates: 1,
      plants: 21,
      fungi: 0,
      chromists: 0,
      total: 68
    },
    {
      name: 'Sudan',
      mammals: 19,
      birds: 27,
      reptiles: 6,
      amphibians: 0,
      fish: 53,
      molluscs: 0,
      otherInvertebrates: 50,
      plants: 17,
      fungi: 0,
      chromists: 0,
      total: 172
    },
    {
      name: 'Tanzania',
      mammals: 45,
      birds: 47,
      reptiles: 41,
      amphibians: 61,
      fish: 218,
      molluscs: 11,
      otherInvertebrates: 137,
      plants: 1031,
      fungi: 0,
      chromists: 0,
      total: 1591
    },
    {
      name: 'Togo',
      mammals: 16,
      birds: 15,
      reptiles: 9,
      amphibians: 2,
      fish: 67,
      molluscs: 4,
      otherInvertebrates: 1,
      plants: 15,
      fungi: 0,
      chromists: 0,
      total: 129
    },
    {
      name: 'Uganda',
      mammals: 32,
      birds: 33,
      reptiles: 8,
      amphibians: 2,
      fish: 56,
      molluscs: 17,
      otherInvertebrates: 17,
      plants: 148,
      fungi: 0,
      chromists: 0,
      total: 313
    },
    {
      name: 'Zambia',
      mammals: 13,
      birds: 22,
      reptiles: 4,
      amphibians: 0,
      fish: 26,
      molluscs: 12,
      otherInvertebrates: 1,
      plants: 59,
      fungi: 0,
      chromists: 0,
      total: 137
    },
    {
      name: 'Zimbabwe',
      mammals: 10,
      birds: 22,
      reptiles: 4,
      amphibians: 6,
      fish: 5,
      molluscs: 0,
      otherInvertebrates: 8,
      plants: 81,
      fungi: 0,
      chromists: 0,
      total: 136
    },
    {
      name: 'Afghanistan',
      mammals: 12,
      birds: 15,
      reptiles: 3,
      amphibians: 1,
      fish: 11,
      molluscs: 0,
      otherInvertebrates: 2,
      plants: 11,
      fungi: 0,
      chromists: 0,
      total: 55
    },
    {
      name: 'Armenia',
      mammals: 7,
      birds: 14,
      reptiles: 7,
      amphibians: 0,
      fish: 4,
      molluscs: 2,
      otherInvertebrates: 6,
      plants: 76,
      fungi: 0,
      chromists: 0,
      total: 116
    },
    {
      name: 'Azerbaijan',
      mammals: 7,
      birds: 17,
      reptiles: 8,
      amphibians: 1,
      fish: 14,
      molluscs: 2,
      otherInvertebrates: 5,
      plants: 47,
      fungi: 1,
      chromists: 0,
      total: 102
    },
    {
      name: 'Bahrain',
      mammals: 3,
      birds: 8,
      reptiles: 4,
      amphibians: 0,
      fish: 35,
      molluscs: 0,
      otherInvertebrates: 13,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 63
    },
    {
      name: 'Cyprus',
      mammals: 7,
      birds: 8,
      reptiles: 5,
      amphibians: 1,
      fish: 39,
      molluscs: 7,
      otherInvertebrates: 16,
      plants: 23,
      fungi: 2,
      chromists: 0,
      total: 108
    },
    {
      name: 'Georgia',
      mammals: 8,
      birds: 15,
      reptiles: 6,
      amphibians: 1,
      fish: 13,
      molluscs: 4,
      otherInvertebrates: 10,
      plants: 70,
      fungi: 5,
      chromists: 0,
      total: 132
    },
    {
      name: 'Iran',
      mammals: 18,
      birds: 28,
      reptiles: 15,
      amphibians: 4,
      fish: 85,
      molluscs: 2,
      otherInvertebrates: 21,
      plants: 18,
      fungi: 0,
      chromists: 0,
      total: 191
    },
    {
      name: 'Iraq',
      mammals: 13,
      birds: 17,
      reptiles: 3,
      amphibians: 1,
      fish: 44,
      molluscs: 1,
      otherInvertebrates: 15,
      plants: 4,
      fungi: 0,
      chromists: 0,
      total: 98
    },
    {
      name: 'Israel',
      mammals: 16,
      birds: 18,
      reptiles: 11,
      amphibians: 1,
      fish: 84,
      molluscs: 12,
      otherInvertebrates: 64,
      plants: 40,
      fungi: 1,
      chromists: 0,
      total: 247
    },
    {
      name: 'Jordan',
      mammals: 13,
      birds: 15,
      reptiles: 6,
      amphibians: 0,
      fish: 28,
      molluscs: 6,
      otherInvertebrates: 55,
      plants: 8,
      fungi: 0,
      chromists: 0,
      total: 131
    },
    {
      name: 'Kazakhstan',
      mammals: 17,
      birds: 27,
      reptiles: 1,
      amphibians: 1,
      fish: 24,
      molluscs: 2,
      otherInvertebrates: 5,
      plants: 21,
      fungi: 1,
      chromists: 0,
      total: 99
    },
    {
      name: 'Kuwait',
      mammals: 7,
      birds: 11,
      reptiles: 4,
      amphibians: 0,
      fish: 37,
      molluscs: 0,
      otherInvertebrates: 13,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 72
    },
    {
      name: 'Kyrgyzstan',
      mammals: 5,
      birds: 15,
      reptiles: 3,
      amphibians: 0,
      fish: 12,
      molluscs: 0,
      otherInvertebrates: 3,
      plants: 23,
      fungi: 0,
      chromists: 0,
      total: 61
    },
    {
      name: 'Lebanon',
      mammals: 10,
      birds: 11,
      reptiles: 8,
      amphibians: 0,
      fish: 43,
      molluscs: 11,
      otherInvertebrates: 9,
      plants: 101,
      fungi: 0,
      chromists: 0,
      total: 193
    },
    {
      name: 'Oman',
      mammals: 9,
      birds: 13,
      reptiles: 8,
      amphibians: 0,
      fish: 76,
      molluscs: 3,
      otherInvertebrates: 29,
      plants: 9,
      fungi: 0,
      chromists: 0,
      total: 147
    },
    {
      name: 'Pakistan',
      mammals: 24,
      birds: 32,
      reptiles: 17,
      amphibians: 1,
      fish: 119,
      molluscs: 0,
      otherInvertebrates: 18,
      plants: 22,
      fungi: 0,
      chromists: 0,
      total: 233
    },
    {
      name: 'Palestine',
      mammals: 5,
      birds: 15,
      reptiles: 4,
      amphibians: 0,
      fish: 16,
      molluscs: 2,
      otherInvertebrates: 2,
      plants: 11,
      fungi: 0,
      chromists: 0,
      total: 55
    },
    {
      name: 'Qatar',
      mammals: 4,
      birds: 10,
      reptiles: 3,
      amphibians: 0,
      fish: 41,
      molluscs: 0,
      otherInvertebrates: 13,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 71
    },
    {
      name: 'Saudi Arabia',
      mammals: 11,
      birds: 19,
      reptiles: 3,
      amphibians: 0,
      fish: 73,
      molluscs: 1,
      otherInvertebrates: 59,
      plants: 5,
      fungi: 0,
      chromists: 0,
      total: 171
    },
    {
      name: 'Syria',
      mammals: 15,
      birds: 17,
      reptiles: 9,
      amphibians: 0,
      fish: 64,
      molluscs: 11,
      otherInvertebrates: 13,
      plants: 45,
      fungi: 0,
      chromists: 0,
      total: 174
    },
    {
      name: 'Tajikistan',
      mammals: 7,
      birds: 14,
      reptiles: 7,
      amphibians: 0,
      fish: 7,
      molluscs: 0,
      otherInvertebrates: 3,
      plants: 20,
      fungi: 1,
      chromists: 0,
      total: 59
    },
    {
      name: 'Turkey',
      mammals: 18,
      birds: 21,
      reptiles: 20,
      amphibians: 10,
      fish: 148,
      molluscs: 47,
      otherInvertebrates: 43,
      plants: 136,
      fungi: 8,
      chromists: 0,
      total: 451
    },
    {
      name: 'Turkmenistan',
      mammals: 9,
      birds: 18,
      reptiles: 6,
      amphibians: 0,
      fish: 14,
      molluscs: 1,
      otherInvertebrates: 6,
      plants: 6,
      fungi: 0,
      chromists: 0,
      total: 60
    },
    {
      name: 'United Arab Emirates',
      mammals: 8,
      birds: 13,
      reptiles: 5,
      amphibians: 0,
      fish: 53,
      molluscs: 0,
      otherInvertebrates: 15,
      plants: 0,
      fungi: 0,
      chromists: 0,
      total: 94
    },
    {
      name: 'Uzbekistan',
      mammals: 10,
      birds: 18,
      reptiles: 9,
      amphibians: 0,
      fish: 9,
      molluscs: 1,
      otherInvertebrates: 2,
      plants: 27,
      fungi: 0,
      chromists: 0,
      total: 76
    },
    {
      name: 'Yemen',
      mammals: 12,
      birds: 17,
      reptiles: 7,
      amphibians: 0,
      fish: 68,
      molluscs: 2,
      otherInvertebrates: 66,
      plants: 165,
      fungi: 0,
      chromists: 0,
      total: 337
    }
];