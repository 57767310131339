import { useTheme } from '../hooks/ThemeProvider';
import { NavLink } from 'react-router-dom';
import { Container, Button } from 'reactstrap';
import Header from '../components/Header';
import VideoBackground from '../components/VideoBackground';
import IntroPanel from '../features/home/IntroPanel';
import FeaturedContent from '../features/home/FeaturedContent';
import NumbersPanel from '../features/home/NumbersPanel';
import HelpPanel from '../features/home/HelpPanel';
import HomePageArticles from '../features/articles/HomePageArticles';
import TopButton from '../components/TopButton';
import { HOME_PAGE_VIDEO_BG } from '../app/shared/VIDEO_BACKGROUNDS';
import { IPBES_REPORT } from '../app/shared/FEATURES';
import signature from '../app/media/signature.png';
import signatureWhite from '../app/media/signature-white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const HomePage = () => {
    const { mode } = useTheme();

    return (
        <Container fluid className='p-0'>
            <VideoBackground video={HOME_PAGE_VIDEO_BG} />
            <Header />

            <Container className='my-5'>
                <div className='w-100 text-center'>
                    <h3 className='pf fw-bold text-success'>Beta Testing Now Live</h3>
                    <p>Welcome to Biodiversity365! Thank you for being here. During this beta phase of development, I'm not just ironing out the kinks; I'm actively seeking feedback to make your Bio365 experience the best it can be. Whether it's a bug report, a suggestion for a new feature, or just your general impressions, anything you can tell me about how to improve your experience will be read, considered, and implemented (if possible). Building a resource for biodiversity education and informed activism is a collaborative process and a journey I'm excited to share with you.</p>
                    <p>To provide feedback, please use the form located at the bottom of every page or reach out to me directly at dmkcodes@gmail.com.</p>
                    <p>Much love,</p>
                    <img 
                        src={mode === 'dark' ? signatureWhite : signature} 
                        alt='signed name Doug' 
                        className='signature mb-4 mx-auto mx-xl-0 ms-3 me-xl-auto'
                    />
                </div>
            </Container>

            <hr />

            <Container className='mt-5 home-page-section'>
                <IntroPanel />
            </Container>

            <Container className='home-page-section'>
                <FeaturedContent feature={IPBES_REPORT} />
            </Container>

            <Container fluid className='home-page-section bg-dark'>
                <NumbersPanel />
            </Container>

            <Container className='home-page-section'>
                <HomePageArticles />
            </Container>

            <Container fluid className='home-page-section mb-5'>
                <HelpPanel />
            </Container>

            <div className='w-100 d-flex justify-content-center'>
                <Button color='success' className='rounded-0'>
                    <NavLink className='nav-link' to='/learn'>
                        Keep Learning<FontAwesomeIcon icon={faArrowRight} className='ms-2' />
                    </NavLink>
                </Button>
            </div>
            
            <TopButton />
        </Container>
    );
};

export default HomePage;